@font-face {
  font-family: 'Sequel Sans';
  font-weight: 700;
  src: local('Sequel Sans'), url(./fonts/Sequel-Sans/Sequel-Sans-Black-Disp.otf) format('opentype');
}

@font-face {
  font-family: 'Sequel Sans';
  font-weight: 500;
  src: local('Sequel Sans'), url(./fonts/Sequel-Sans/Sequel-Sans-Bold-Disp.otf) format('opentype');
}

@font-face {
  font-family: 'Sequel Sans';
  font-weight: 400;
  src: local('Sequel Sans'), url(./fonts/Sequel-Sans/Sequel-Sans-Roman-Disp.otf) format('opentype');
}

:root {
  --primary700: #E34D41;
  --primary600: #F7BC15;
  --primary500: #5C82E1;
  --primary300:  #BDBFDB;
  --primary100: #3FA75B;

  --black: #3B3B3B;
  --gray: #D9D9D9;
  --white: #FFFFFF;

  
 --doc-height: 100%;
}

#root{
  padding-top: env(safe-area-inset-top);
  width: 100vw;
  height: calc(100vh - env(safe-area-inset-top));
  height: calc(var(--doc-height) - env(safe-area-inset-top));
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
  /* overflow-x: hidden; */
}

body {
  width: 100%;
  height: 100%;
  
  height: 100vh; /* fallback for Js load */
  height: var(--doc-height);
  margin: 0;
  background-color: var(--white);
  color: var(--white);
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

img {

  user-select: none;
}

textarea:focus, input:focus, select:focus, button:focus{
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media only screen and (max-width: 800px) {
}